<template>
  <div id="pc" class="etnersWrap wrapbg">
    <router-view></router-view>
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>

  export default {
    name      : 'UserPopLayout',
    components: {

    },
  }
</script>
